<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-page-header @back="$router.go(-1)" content="明细详情"></el-page-header>
    <div class="content-box">
      <el-table :loading="loading" :data="tableData" :stripe="true" border fit>
        <el-table-column label="序号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间"></el-table-column>
        <el-table-column prop="orderId" label="订单编号"></el-table-column>
        <el-table-column prop="productName" label="商品名称"></el-table-column>
        <el-table-column prop="mobile" label="下单电话"></el-table-column>
        <el-table-column prop="amount" label="实收金额"></el-table-column>
        <el-table-column label="订单状态">
          <template slot-scope="scope">{{ scope.row.orderStatus | filterStatus(that) }}</template>
        </el-table-column>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getMarketingSalesDetailInfo } from '@/api/shop/statement/salesDetails';
import { getDict } from "@/common/js/common";
import { mapState } from 'vuex';
export default {
  data () {
    return {
      that: this,
      crumbs: new Map([
        ['特惠商城'], ['对账报表'], ['销售明细']
      ]),
      loading: true,
      tableData: [],
      limit: 1,
      page: 1,
      total: 0,
      id: '',
      from: '',
      dateStr: ''
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    getDict('order-status')
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getMarketingSalesDetailInfo()
  },
  methods: {
    // 获取销售明细详情
    getMarketingSalesDetailInfo() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = { hotelId: this.$route.query.hotelId, dateStr: this.$route.query.date };
      getMarketingSalesDetailInfo(query, params).then(({ success, records, total }) => {
        if (success) {
          this.loading = false
          this.total = total
          this.tableData = records
        }
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num
      this.getMarketingSalesDetailInfo()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num
      this.getMarketingSalesDetailInfo()
    },
  },
  filters: {
    filterStatus(val, that) {
      if (val && that.dictData['order-status']) return that.dictData['order-status'][val] || val
    }
  }
}
</script>
